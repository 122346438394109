<template>
  <form
    id="invForm"
    action
    data-vv-scope="inventory-form"
    @submit.prevent="validateForm('inventory-form')"
  >
    <v-row>
      <v-col cols="12">
        <v-row align="center">
          <v-col class="py-1" cols="12" md="6">
            <label>{{ $t("Descripción") }}</label>

            <v-text-field
              data-vv-validate-on="blur"
              outlined
              dense
              class="inputs"
              v-model="inventory.description"
              v-validate="'required|min:2'"
              :error-messages="errors.collect('inventory-form.description')"
              color="secondary"
              data-vv-name="description"
            />
          </v-col>
          <v-col class="py-1" cols="12" md="6">
            <label>{{ $t("inventorys.product_cody") }}</label>
            <v-text-field
              outlined
              data-vv-validate-on="blur"
              dense
              v-model="inventory.product_cody"
              v-validate="'required'"
              class="inputs"
              :error-messages="errors.collect('inventory-form.product_cody')"
              color="secondary"
              data-vv-name="product_cody"
            />
          </v-col>
          <v-col class="py-1 mt-n3" cols="12" md="6">
            <label style="line-height: 3">{{
              $t("inventorys.purchase_price")
            }}</label>
            <number-input-with-separators
              :value="inventory"
              valueKey="product_price"
              ref="money"
              outlined
              hide-details
              dense
              rules="required"
              type="number"
              v-on:keypress="isNumber($event)"
              color="secondary"
            ></number-input-with-separators>
          </v-col>
          <v-col class="py-1 mt-n3" cols="12" md="6">
            <label style="line-height: 3">{{
              $t("inventorys.sale_price")
            }}</label>
            <number-input-with-separators
              :value="inventory"
              valueKey="sale_price"
              ref="money2"
              outlined
              hide-details
              dense
              rules="required"
              type="number"
              v-on:keypress="isNumber($event)"
              color="secondary"
            ></number-input-with-separators>
          </v-col>
          <v-col class="py-1" cols="12" md="6">
            <label>{{ $t("inventorys.cantidad_initial") }}</label>

            <v-text-field
              outlined
              data-vv-validate-on="blur"
              dense
              type="number"
              v-on:keypress="isNumber($event)"
              v-model="inventory.quantity"
              class="inputs"
              v-validate="'required'"
              :error-messages="errors.collect('inventory-form.quantity')"
              color="secondary"
              data-vv-name="quantity"
            />
          </v-col>
          <v-col class="py-1" cols="12" md="6">
            <label>{{ $t("inventorys.min_stock") }}</label>

            <v-text-field
              outlined
              data-vv-validate-on="blur"
              dense
              type="number"
              v-on:keypress="isNumber($event)"
              v-model="inventory.min_stock"
              class="inputs"
              v-validate="'required'"
              :error-messages="errors.collect('inventory-form.min_stock')"
              color="secondary"
              data-vv-name="min_stock"
            />
          </v-col>
          <v-col class="py-1" cols="12" md="6">
            <label>{{ $t("inventorys.add_file") }}</label>
            <v-file-input
              dense
              class="inputs pointer"
              v-model="inventory.product_file_new"
              data-vv-validate-on="blur"
              prepend-inner-icon="mdi-paperclip"
              prepend-icon
              outlined
              :placeholder="placeHolderFileInput"
              accept="image/*"
            >
              <template v-slot:selection="{ text }">
                {{ formatFile(text) }}
              </template>
            </v-file-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-1" cols="9" md="4">
            <label>{{ $t("Marca") }}</label>
            <v-select
              outlined
              dense
              class="inputs"
              v-model="inventory.brand_id"
              :items="brand"
              item-text="brand_name"
              item-value="id"
              v-validate="''"
              :error_messages="errors.collect('inventory-form.brand')"
              vv_name="brand"
              @select="inventory.category_id = null"
            />
          </v-col>
          <v-col class="py-1 mt-2 mt-sm-0 mb-md-n3" cols="3" md="2">
            <BrandsNew class="mt-1" @save="fetchProductBrand" :small="true" />
          </v-col>
          <v-col class="py-1" cols="9" md="4">
            <label>{{ $t("Categoría") }}</label>
            <v-select
              outlined
              dense
              class="inputs"
              v-model="inventory.category_id"
              :items="brand_categories"
              item-text="category_name"
              item-value="id"
              v-validate="''"
              :error_messages="errors.collect('inventory-form.category')"
              vv_name="category"
              @select="setBrand"
            />
          </v-col>
          <v-col class="py-1 mt-2 mt-sm-0 mb-md-n3" cols="3" md="2">
            <CategoryNew
              class="mt-1"
              @save="fetchProductCategory"
              :small="true"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <div class="pt-3 text-right" v-if="buttons !== false">
      <v-btn
        outlined
        @click="$emit('cancel')"
        style="height: 25px"
        class="mr-3"
        elevation="0"
        >{{ $t("cancel") }}</v-btn
      >
      <v-btn
        style="
          width: 100px !important;
          color: #363533;
          height: 25px;
          padding-top: 10px !important;
        "
        elevation="0"
        type="submit"
      >
        <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
        {{ $t("save", { name: $t("") }) }}
      </v-btn>
    </div>
  </form>
</template>
<script>
import constants from "@/constants";
import { mapActions } from "vuex";
export default {
  name: "InventorysForm",
  props: ["title", "inventory", "action"],

  mounted() {
    this.reload();
  },
  components: {
    BrandsNew: () => import("@/views/brand/BrandNew"),
    CategoryNew: () => import("@/views/category/CategoryNew"),
    SupplierNew: () => import("@/views/supplier/SupplierNew"),
    NumberInputWithSeparators: () =>
      import("@/components/ui/NumberInputWithSeparators"),
  },
  data() {
    return {
      userProcessed: false,
      menu: false,
      category: [],
      brand: [],
      suppliers: [],
      supplier: null,
    };
  },
  methods: {
    setBrand() {
      let c = this.category.find((x) => x.id === this.inventory.category_id);
      this.inventory.brand_id = c.brand_id;
    },
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9.]+$/.test(char)) return true;
      else e.preventDefault();
    },
    reload() {
      console.log("reload");
      this.fetchProductCategory();
      this.fetchProductBrand();
      this.fetchAllSuppliers();
    },
    ...mapActions("category", ["getProductCategory"]),
    fetchProductCategory() {
      this.getProductCategory().then((data) => {
        this.category = data;
      });
    },
    ...mapActions("brand", ["getProductBrand"]),
    fetchProductBrand() {
      this.getProductBrand().then((data) => {
        this.brand = data;
      });
    },

    ...mapActions("supplier", ["getAll"]),

    fetchAllSuppliers() {
      this.getAll().then((data) => {
        this.suppliers = data;
        //console.log(this.suppliers);
      });
    },
    supplierValue() {
      //console.log(this.inventory.supplier_id);
    },
    async validateForm(scope) {
      this.errors.clear();
      let money_val = await this.$refs.money.validate();
      let money_val2 = await this.$refs.money2.validate();
      let result = await this.$validator.validateAll(scope);
      if (result && money_val && money_val2) {
        this.$emit("validated", this.inventory);
        return true;
      } else {
        return false;
      }
    },
    formatFile(data) {
      if (data && data !== "null") {
        data = data.split("/");
        data = data[data.length - 1];
        let type = data.split(".");
        type = type[type.length - 1];
        return `${data.substr(0, 4)}...${type}`;
      }
    },
  },
  computed: {
    brand_categories() {
      return this.category.filter(
        (x) => x.brand_id === this.inventory.brand_id
      );
    },

    placeHolderFileInput() {
      if (this.inventory.hasOwnProperty("product_file")) {
        let data = this.inventory.product_file;
        if (data && data !== "null") {
          data = data.split("/");
          data = data[data.length - 1];
          let type = data.split(".");
          type = type[type.length - 1];
          return `${data.substr(0, 5)}... .${type}`;
        }
      }
      return "Adjuntar";
    },
  },
};
</script>
<style lang="sass">
.inputs .v-input__slot
  margin-top: -10px
#invForm
  #infoSupplier
    td:first-child
      width: 250px
      color: var(--v-gris1-base) !important
    td
      max-height: 30px
      padding: 0
      padding-top: 5px
    label
      display: block
      line-height: 1
      height: 35px !important
  .v-text-field__details
    display: none
  label
    display: block
    height: 20px
    line-height: 1
</style>